<template>
	<v-card flat elevation="0" class="min-height-screen h-full">
		<div class="h-full w-full">
			<div class="d-flex h-full w-full align-center justify-center">
				<loader />
			</div>
		</div>
	</v-card>
</template>

<script>
/* eslint-disable indent */
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import loadScript from '@/utils/load-script'
import currencyFormatter from '@/utils/currency-formatter'
import { confirmOrderPayment } from '@/services/order'
import { SUCCESS } from '@/constants/status-code'

const PUBLISHABLE_KEY = process.env.VUE_APP_STRIP_PUBLISHABLE_KEY

export default {
	name: 'PaymentSuccess',
	mixins: [AuthMixin],
	components: {
		Loader,
	},
	data() {
		return {
			currencyFormatter,
			isLoading: false,
			hasError: false,
			message: '',
			clientSecret: this.$route.query.payment_intent_client_secret,
			paymentIntent: this.$route.query.payment_intent,
			order: null,
		}
	},
	beforeRouteEnter(to, _, next) {
		const paymentIntentClientSecret = to.query.payment_intent_client_secret
		const paymentIntent = to.query.payment_intent

		// eslint-disable-next-line operator-linebreak
		const hasPaymentIntentClientSecret =
			typeof paymentIntentClientSecret === 'string' && paymentIntentClientSecret
		const hasPaymentIntent = typeof paymentIntent === 'string' && paymentIntent

		if (!hasPaymentIntentClientSecret || !hasPaymentIntent) {
			next((vm) => {
				vm.$router.push({ name: 'home', query: { token: vm.authToken } })
			})
			return
		}
		next()
	},
	created() {
		const loadStripeScriptAndPayment = async () => {
			this.isLoading = true

			await Promise.all([
				loadScript('https://js.stripe.com/v3/'),
				this.fetchConfirmOrderPayment(),
			]).catch(() => {
				this.hasError = true
			})

			const stripe = window.Stripe(PUBLISHABLE_KEY)

			if (stripe) {
				// Retrieve the PaymentIntent
				this.isLoading = true

				try {
					const { paymentIntent } = await stripe.retrievePaymentIntent(this.clientSecret)
					switch (paymentIntent.status) {
						case 'succeeded':
							this.paymentStatus = 'succeeded'
							this.message = 'Success! Payment received.'
							this.$store.commit('CLEAR_CART_PRODUCTS')
							break
						case 'processing':
							this.paymentStatus = 'processing'
							this.message = "Payment processing. We'll update you when payment is received."
							this.$store.commit('CLEAR_CART_PRODUCTS')
							break
						case 'requires_payment_method':
							// Redirect your user back to your payment page to attempt collecting
							// payment again
							this.paymentStatus = 'requires_payment_method'
							this.message = 'Payment failed. Please try another payment method.'
							break
						default:
							this.paymentStatus = 'failed'
							this.message = 'Something went wrong.'
							break
					}
				} catch (e) {
					this.hasError = true
				}
				this.isLoading = false
				this.$store.commit('RESET_VOUCHER_CODE_ID') // remove voucher code
				// redirect to order details
				window.location.href = `${this.baseUrl}/me/order/${this.order.orderId}?token=${this.authToken}`
			}
		}

		loadStripeScriptAndPayment()
	},
	methods: {
		async fetchConfirmOrderPayment() {
			if (!this.paymentIntent) return
			const res = await confirmOrderPayment(this.authToken, { paymentIntentId: this.paymentIntent })
			const { data, statusCode } = res.data

			if (statusCode !== SUCCESS) throw new Error('somehing went wrong')

			this.order = data
		},
	},
}
</script>
